import axios from 'axios'
import router from '../router'
import { EventBus } from '@/event-bus'

export const mutations = {
  setUser (state) {
    axios
      .get('/users/get-user')
      .then(response => {
        let user = response.data
        localStorage.setItem('currentLoginUser', JSON.stringify(user))
        state.currentLoginUser = user
        router.push({ path: '/', query: { afterLogin: true } })
      })
      .catch(e => {
        console.error(e)
      })
  },
  updateUserData (state) {
    axios
      .get('/users/get-user')
      .then(response => {
        let user = response.data
        localStorage.setItem('currentLoginUser', JSON.stringify(user))
        state.currentLoginUser = user
      })
  },
  // TODO integrate into set and update user methods
  setUserProjects (state) {
    axios
      .get('/users/get-user-projects')
      .then(response => {
        let userProjects = response.data
        localStorage.setItem('currentLoginUserProjects', JSON.stringify(userProjects))
        state.currentLoginUserProjects = userProjects
      })
  },
  updateSelectedProject (state, payload) {
    localStorage.setItem('selectedProject', JSON.stringify(payload))
    state.selectedProject = payload
  },
  clearAuth (state) {
    localStorage.clear()
    state.currentLoginUser = undefined
    state.selectedProject = undefined
    state.gisCredentials = undefined
    router.push('/info')
  },
  selectProject (state, payload) {
    let { project, path } = payload
    localStorage.setItem('selectedProject', JSON.stringify(project))
    state.selectedProject = project
    let newPath = `/project/${project.id}/${path}`
    if (router.currentRoute.path.toLowerCase() !== newPath.toLowerCase()) router.push(newPath)
  },
  removeProject (state, payload) {
    localStorage.removeItem('selectedProject')
    state.selectedProject = undefined
  },
  updateTopRecord (state, { message, chatID }) {
    const { createdAt, decryptedBody, messageKey, authorID, firstName, surname } = message
    let chat = state.chats.find(c => c.id == chatID)
    chat.topRecord.createdAt = createdAt
    // TODO add translation
    chat.topRecord.decryptedBody = (messageKey === 'DELETED') ? 'Сообщение удалено' : decryptedBody
    chat.topRecord.messageKey = messageKey
    chat.topRecord.author.id = authorID
    chat.topRecord.author.firstName = firstName
    chat.topRecord.author.surname = surname

    state.chats.sort((a, b) => b.topRecord.createdAt - a.topRecord.createdAt) // sort the list after new received message
  },
  setChatFilter (state, payload) {
    state.filterByChatType = payload
    if (payload === 'ALL') {
      state.chats.forEach(chat => chat.visible = state.archivedChatsShown ? chat.archived : !chat.archived)
      return
    }

    state.chats.forEach(chat => {
      if (state.archivedChatsShown) {
        chat.visible = chat.archived && chat.type === payload
      } else {
        chat.visible = !chat.archived && chat.type === payload
      }
    })
  },
  setWebsocketConnection (state, payload) {
    state.establishedConnection = payload
  },
  updateLastMessageAttributes (state, payload) {
    const { messageID, createdAt, chatID, numberOfUnreadMessages } = payload
    let find = state.chats.find(chat => chat.id == chatID)
    if (find) {
      find.lastMessageTime = createdAt
      find.lastMessageID = messageID
      find.numberOfUnreadMessages = numberOfUnreadMessages
    }
  },
  increaseNumberOfUnreadMessages (state, payload) {
    let chat = state.chats.find(chat => chat.id == payload)
    chat.numberOfUnreadMessages += 1
  },
  setOnlineUsers (state, payload) {
    state.onlineUsers = payload
  },
  removeFromOnlineUsers (state, payload) {
    state.onlineUsers.splice(state.onlineUsers.indexOf(payload), 1)

    // set logout time for companion in case of Direct chat
    const chat = state.chats.find(chat => chat.type === 'DIRECT' && chat.companionID === payload)
    if (chat) chat.companionLogoutTime = Date.now()
  },
  async setGisCredentials (state) {
    let settingsResponse = await axios.post('/users/get-settings')
      .catch(er => {
        if (er.response.status === 401) EventBus.$emit('logout')
      })

    let userSettings = settingsResponse.data
    axios
      .post('/settings/get-gis-credentials')
      .then(response => {
        let gisCredentials = response.data
        if (userSettings.gisLogin && userSettings.gisPassword) {
          gisCredentials.GIS_USERNAME = userSettings.gisLogin
          gisCredentials.GIS_PASSWORD = userSettings.gisPassword
        }
        state.gisCredentials = gisCredentials
      })
  },
  refreshChatList (state, payload) {
    const archivedChatsShown = state.chats.some(chat => chat.archived && chat.visible)
    Object.keys(payload).forEach(key => {
      if (!payload[key].archived && (state.filterByChatType === 'ALL' || state.filterByChatType === payload[key].type) && !archivedChatsShown) {
        payload[key].visible = true
      }

      const index = state.chats.findIndex(c => c.id == key)
      if (index !== -1) state.chats.splice(index, 1, payload[key]) // update chat in array
      else state.chats.push(payload[key])
    })

    state.chats.sort((a, b) => b.topRecord.createdAt - a.topRecord.createdAt) // sort the list
  },
  saveSubscription (state, { id, subscription }) {
    if (state.subscriptions.has(id)) return
    state.subscriptions.set(id, subscription)
  },
  unsubscribeAllChats (state) {
    state.subscriptions.forEach((value) => value.unsubscribe())
    state.subscriptions.clear()
    state.chats = []
    state.onlineUsers = []
  },
  unsubscribe (state, chatID) {
    const sub = state.subscriptions.get(chatID)
    if (sub) {
      sub.unsubscribe()
      state.subscriptions.delete(chatID)
    }
  },
  unsubscribeAll (state) {
    state.establishedConnection = false
    state.subscriptions.forEach((value) => value.unsubscribe())
    state.subscriptions.clear()
  },
  removeChatFromList (state, chatID) {
    const index = state.chats.findIndex(chat => chat.id == chatID)
    if (index !== -1) state.chats.splice(index, 1)
  },
  setActiveChatId (state, chatID) {
    state.activeChatID = chatID
  },
  setActiveModalChat (state, chatID) {
    state.activeModalChat = chatID
  },
  showArchivedChats (state) {
    state.chats.forEach(chat => chat.visible = (state.filterByChatType === 'ALL' && chat.archived) || (chat.archived && chat.type === state.filterByChatType))
    state.archivedChatsShown = true
  },
  showAllChats (state) {
    state.chats.forEach(chat => chat.visible = (state.filterByChatType === 'ALL' || chat.type === state.filterByChatType) && !chat.archived)
    state.archivedChatsShown = false
  },
  renameChat (state, { message, chatID }) {
    const chat = state.chats.find(chat => chat.id == chatID)
    chat.name = JSON.parse(message.decryptedBody)[1]
  },
  setReturnToSearchLpDialog (state, bool) {
    state.returnToSearchLpDialog = bool
  },
  setProjectPanelExpanded (state, bool) {
    state.projectPanelExpanded = bool
  },
  updateMapView (state, { mapId, view, date }) {
    const viewIndex = state.mapViews.findIndex(el => el.mapId === mapId)
    if (viewIndex === -1) {
      state.mapViews.push({ mapId, view, date })
    } else {
      state.mapViews[viewIndex] = { mapId, view, date }
    }
    localStorage.setItem('mapViews', JSON.stringify(state.mapViews))
  },
  updateMapLayersConfig (state, { mapId, layers, date }) {
    const itemIndex = state.mapLayersConfig.findIndex(el => el.mapId === mapId)
    if (itemIndex === -1) {
      state.mapLayersConfig.push({ mapId, layers, date })
    } else {
      state.mapLayersConfig[itemIndex] = { mapId, layers, date }
    }
    localStorage.setItem('mapLayersConfig', JSON.stringify(state.mapLayersConfig))
  },
  setFeatureCardCollapsed (state, bool) {
    state.isFeatureCardCollapsed = bool
  },
  updateMapGroups (state, { mapId, groups }) {
    const indexFromState = state.mapGroups.findIndex(el => el.mapId === mapId)
    if (indexFromState === -1) {
      state.mapGroups.push({ mapId, groups })
    } else {
      state.mapGroups[indexFromState] = { mapId, groups }
    }
  },
  updateEtActionSettings (state, { etId, settings }) {
    const indexFromState = state.etActionSettings.findIndex(el => el.etId === etId)
    if (indexFromState === -1) {
      state.etActionSettings.push({ etId, settings })
    } else {
      state.etActionSettings[indexFromState] = { etId, settings }
    }
    localStorage.setItem('etActionSettings', JSON.stringify(state.etActionSettings))
  },
  updateOpenedArticleIds (state, ids) {
    state.openedArticleIds = ids
  }
}
